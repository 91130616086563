<template>
	<div class="reservations position-relative">
		<div class="px-20 pt-20 pb-10 mb-20 bg-white border-bottom border-brd-primary position-relative">
			<div class="page-header">
				<h1 class="page-title">Брони</h1>

			</div>
		</div>
	</div>
</template>

<script>
    import CommonService from "../services/CommonService";

    export default {
        name: "reservations",
        data() {
            return {
                title: "Брони"
            };
        },
        methods: {

        },
        mounted() {
            CommonService.setPageTitle(this.title);
        }
    };
</script>